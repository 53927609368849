import * as Yup from "yup";
import { isPossiblePhoneNumber } from "react-phone-number-input";

const PUCBillingInfoSchema = Yup.object({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  zip: Yup.string().required("Required"),
  mobile: Yup.string()
    .required("Required")
    .test(
      "isValidPhone",
      "Phone Number is not valid",
      (value) => value && isPossiblePhoneNumber(value)
    ),
});

export default PUCBillingInfoSchema;
