import React from "react";
import Link from "next/link";
import API from "Services/api";
import { useRouter } from "next/router";
import { TimeSlots } from "Lib/constants";
import moment from "moment";
export default function Entries({ dataItem, url }) {
  // console.log(dataItem);
  const router = useRouter();
  const { orderNo, dateOfReservation, _id, buttonFlags } = dataItem;
  const { change, cancel } = buttonFlags;

  const handleClick = async () => {
    try {
      const { data } = await API.get(`/reservations/${_id}/details`);
      console.log(data);
      if (data.message === "Success") {
        localStorage.removeItem("order_data");
        localStorage.removeItem("booking_data");
        localStorage.removeItem("customer_input");
        localStorage.removeItem("bookingDetails");
        localStorage.setItem("order_data", JSON.stringify(data?.data));
        console.log(data?.data);
        localStorage.setItem("should_show", JSON.stringify(false));
        router.push("/" + url + "/reserve-confirmation");
      }
    } catch (error) {
      alert(error);
    }
  };

  const handleChangeReserv = () => router.push(`change-reservation/${_id}`);
  const handleCancelReserv = () =>
    router.push(`/${url}/cancel-reservation/${_id}`);

  return (
    <div>
      <div className="row line-bottom">
        <div className="col-xl-3 col-md-3 col-3">
          <p className="less-padding">
            <Link href={`/${url}/reservation-view/${_id}`}>
              <a href="#">{orderNo}</a>
            </Link>
          </p>
        </div>
        <div className="col-xl-3 col-md-3 col-3">
          <p className="less-padding">
            {moment(dateOfReservation).format("MM/DD/YYYY")}
          </p>
        </div>
        <div className="col-xl-5 col-md-5 col-5">
          {/* <Link href={`change-reservation/${_id}`}>
            <a className="btn btn-primary btn-outline-primary">Change</a>
          </Link> */}
          <button
            className="btn btn-primary btn-outline-primary"
            onClick={handleChangeReserv}
            disabled={!change}
          >
            Change
          </button>
          {/* <Link href={`/diamondhead/cancel-reservation/${_id}`}>
            <a className="btn btn-primary btn-outline-primary ml-2">Cancel</a>
          </Link> */}
          <button
            className="btn btn-primary btn-outline-primary ml-2"
            onClick={handleCancelReserv}
            disabled={!cancel}
          >
            Cancel
          </button>

          <Link href={`/${url}/reservation-view/${_id}`}>
            <a className="btn btn-primary btn-outline-primary ml-2">View</a>
          </Link>
        </div>
      </div>
    </div>
  );
}
