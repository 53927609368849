import React from "react";

export default function PrivacyPolicy() {
  return (
    <div>
      <div className="container">
        <div className="row row-padding">
          <div className="col-xl-12">
            <h2>Privacy policy</h2>
            <p>
              <strong>
                Protecting your privacy is essential to ProPark, Inc.
              </strong>
              <br />
              The purpose of this notice is to help you understand what personal
              information we collect, how we use it and when we disclose such
              information with third parties. ProPark, Inc. will only share
              personal customer information with non-affiliated third parties
              only as permitted by law. Third parties in which information is
              shared are required to continue to maintain the security and
              confidentiality of the information. It is our practice to provide
              you notice when your personal information may be shared with
              others, and you will have an opportunity to decide whether that
              information may be disclosed.
              <br />
              <br />
              <strong>Information We Collect</strong>
              <br />
              ProPark, Inc. collects information about its customers. This basic
              information identifies our customers and allows us to contact
              them. ProPark, Inc. collects and uses this information to
              efficiently operate its business and to help it deliver quality
              service to you, its valued customer. ProPark, Inc. affords its
              customers the ability to access their contact information and
              payment information for the limited purpose of viewing and, in
              certain instances, updating that information. A copy of the prior
              contact information will also be maintained.
              <br />
              <br />
              <strong>Information We Share</strong>
              <br />
              We may disclose customer information to third parties that perform
              services on our behalf. We may also disclose information to Agents
              and Service Providers, Prospective Purchasers, Government and Law
              Enforcement Agencies and when we are permitted to or required to
              do so by law.
              <br />
              <br />
              “Agents and Service Providers: To ensure quality service to our
              customers, ProPark, Inc. engages other companies, subsidiaries and
              individuals to perform certain functions and/or services. Examples
              of these functions and/or services include processing credit card
              payments, providing marketing assistance, removing repetitive
              information from customer lists and offering electronic mail
              services and making available digital certificates.
              <br />
              <br />
              “Business Transfers/Prospective Purchasers: As we continue to
              develop our business, ProPark, Inc. may sell or purchase assets.
              In such transactions, customer information is generally disclosed.
              <br />
              <br />
              “Protection of ProPark, Inc. and Others: ProPark, Inc. may access
              and disclose your personal information if required to do so by law
              or in the good faith belief that such action is necessary to: (a)
              confirm to the edicts of the law or comply with legal process
              served on ProPark, Inc. or the site; (b) respond to a court order
              or other governmental or law enforcement authority or regulatory
              agency; (c) enforce or apply our Services Agreement or other
              agreements; (d) protect the rights, property, or safety of
              ProPark, Inc., our customers, or others or (e) reduce credit risk
              and protection from fraud.
              <br />
              <br />
              <strong>Confidentiality and Security Procedures</strong>
              <br />
              All personal customer information is deemed to be confidential in
              nature. Our employees may access such information only when there
              is an appropriate reason to do so. Employees who violate these
              standards will be subject to strict disciplinary measures.
              ProPark, Inc. also maintains physical, electronic and procedural
              safeguards to protect the confidentiality of your personal
              information.
              <br />
              <br />
              By choosing to visit{" "}
              <a href="http://www.propark.org">http://www.propark.org</a> and
              subsequent pages, you agree that your visit and any dispute that
              may arise regarding the protection of your privacy during such
              visit are subject to this Privacy Policy.
              <br />
              <br />
              <strong>Correspondence</strong>
              <br />
              From time to time, ProPark, Inc. may contact you by electronic
              mail regarding our services. These communications are intended to
              inform you of important information regarding your account, or
              about general services provided by ProPark, Inc.
              <br />
              <br />
              For more information, call us at our main office at 808-207-2854
              or e-mail us at{" "}
              <a href="mailto:DiamondHead@propark.org">
                DiamondHead@propark.org
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
