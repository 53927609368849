import Link from "next/link";

const Index = ({
  children,
  classType,
  title1,
  title2,
  url,
  isReserve = true,
  isHeadShow = true,
}) => {
  return (
    <>
      <div className={classType + ' ' + url + 'Banner'}>
        <header className="header-repeater">
          <div className="container">
            <div className="row">
              <div className="col-xl-7 col-lg-7 col-12">
                <Link href="/">
                  <a className="navbar-brand">
                    <img
                      src="/images/go-hawaii-state-parks.png"
                      alt="Go Hawai'i state Parks logo"
                      width="414"
                      className="img-fluid"
                    />
                  </a>
                </Link>
              </div>

              <div className="col-xl-5 col-lg-5 col-12 link-top text-end">
                {isReserve && (
                  <Link href={"/" + url + "/change"}>
                    <a>Change/Cancel/View Your Reservation</a>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </header>

        {isHeadShow && (
          <div className="container">
            <h1 className="display-1">
              {title1}
              <span className="Hide">
                <br />
              </span>{" "}
              {title2}
            </h1>
            {children}
          </div>
        )}
      </div>
      <div className="blue"></div>
    </>
  );
};

export default Index;
