import React, { useEffect, useContext, useState } from 'react';
import Link from 'next/link';
import { OrderContext } from 'Lib/contexts/OrderContext';
import ParkingOrders from '../ReservationDetails/ParkingOrders';
import EntryOrder from '../ReservationDetails/EntryOrder';
import { Formik, Form } from 'formik';
import { Loader } from 'Atoms/';
import { MyTextInput } from 'Atoms/Formik';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { CRValidationSchema } from 'Lib/validators';
import { useRouter } from 'next/router';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import Timer from 'src/components/Timer';
import { TimerContext } from 'Lib/contexts/TimerContext';

export default function Index({park, url}) {
  // const { timer, clearTimer, getDeadTime } = useContext(TimerContext);
  const router = useRouter();
  const orderCtx = useContext(OrderContext);
  const [loading, setLoading] = useState(false);
  const { currentOrder, setCurrentOrder, setInputData } = orderCtx;
  console.log('currentOrder', currentOrder);
  useEffect(() => {
    if (
      !localStorage.getItem('booking_details') ||
      !localStorage.getItem('customer_input')
    ) {
      router.push('/' + url);
    } else {
      setLoading(true);
      const bookingData = JSON.parse(localStorage.getItem('booking_details'));
      
      if(bookingData.parkId === park) {
        setCurrentOrder(bookingData);
        const customerInput = JSON.parse(localStorage.getItem('customer_input'));
        setInputData(customerInput);
        // clearTimer(getDeadTime());
      }
      else {
        router.push("/" + url);
      }
    }
  }, []);
  const { timer, clearTimer, getDeadTime, stopTimer } =
    useContext(TimerContext);

  const [qty, setQty] = useState(1);

  if (!loading) {
    return <Loader />;
  }
  var customerValues = {
    firstName: '',
    lastName: '',
    email: '',
    verifyemail: '',
    country: 'United States',
    address: '',
    city: '',
    state: 'Alabama',
    zipcode: '',
    telephone: ''
  };

  const handleUpdateQty = () => {
    const bookingData = JSON.parse(localStorage.getItem('booking_data'));
    if (bookingData.bookingDetails.pricingDetails.length === 1) {
      let newData = {
        ...bookingData,
        bookingDetails: {
          ...bookingData.bookingDetails,
          pricingDetails: [
            { ...bookingData.bookingDetails.pricingDetails[0], qty }
          ]
        }
      };
      localStorage.setItem('booking_data', JSON.stringify(newData));
    } else {
      let newData = {
        ...bookingData,
        bookingDetails: {
          ...bookingData.bookingDetails,
          pricingDetails: [
            { ...bookingData.bookingDetails.pricingDetails[0] },
            { ...bookingData.bookingDetails.pricingDetails[1], qty }
          ]
        }
      };

      localStorage.setItem('booking_data', JSON.stringify(newData));
    }
    router.back();
  };
  if (localStorage.getItem('booking_data')) {
    const bookingData = JSON.parse(localStorage.getItem('booking_data'));
    const { customerDetails } = bookingData;
    console.log(customerDetails);
    customerValues = {
      ...customerDetails,
    };
  }

  const parkingTypeOrders = currentOrder?.pricingDetails?.filter(
    (item) => item.ticket === 'Parking'
  );
  const entryTypeOrders = currentOrder?.pricingDetails?.filter(
    (item) => item.ticket === 'Entry'
  );
  console.log('entryTypeOrders', entryTypeOrders);
  const { total } = currentOrder;

  return (
    <div>
      <div className="container">
        <div className="row row-padding">
          <div className="col-md-8 col-sm-8 col-xs-12">
            <h1>Reservation details</h1>
          </div>
          <Timer />
          <div className="col-md-12">
            <p>
              Please remember, once payment has been completed, orders can no
              longer be cancelled, so please review your items and pickup time
              carefully. Mahalo!
            </p>
          </div>
        </div>
        <div className="row line-bottom">
          <div className="col-xl-1 col-md-2 col-2">
            <p className="less-padding">
              <strong>Ticket</strong>
            </p>
          </div>
          <div className="col-xl-2 col-md-2 col-3">
            <p className="less-padding text-center">
              <strong>Qty</strong>
            </p>
          </div>
          <div className="col-xl-2 col-md-2 col-2">
            <p className="less-padding">
              <strong>Date</strong>
            </p>
          </div>
          <div className="col-xl-3 col-md-4 col-3">
            <p className="less-padding">
              <strong>Time</strong>
            </p>
          </div>
          <div className="col-2 text-end">
            <p className="less-padding">
              <strong>Price</strong>
            </p>
          </div>
        </div>
        {parkingTypeOrders?.map((dataItem, index) => (
          <ParkingOrders key={index} dataItem={dataItem} />
        ))}
        {entryTypeOrders?.map((dataItem, index) => (
          <EntryOrder key={index} dataItem={dataItem} />
        ))}

        <div className="row line-bottom">
          <div className="col-xl-2 col-md-2 col-2"></div>
          <div className="col-md-1 col-1"></div>
          <div className="col-xl-2 col-md-3 col-3"></div>
          <div className="col-xl-3 col-md-4 col-4">
            <p className="less-padding text-end">Total</p>
          </div>
          <div className="col-2 text-end">
            <p className="less-padding">${total}</p>
          </div>
        </div>

        <div className="row row-padding-top">
          <div className="col-md-12">
            <Link href={"/" + url}>
              <a
                className="btn btn-primary btn-outline-primary"
                onClick={() => {
                  localStorage.removeItem('customer_input');
                }}
              >
                Cancel
              </a>
            </Link>
            <Link href={"/" + url}>
              <a className="btn btn-primary btn-outline-primary ml-2">Change</a>
            </Link>
          </div>
          <div
            id="collapsePay"
            style={{ width: '100%', paddingLeft: '15px', paddingright: '15px' }}
          >
            <div className="row">
              <div className="col-12">
                <h4>Customer details:</h4>
              </div>
            </div>
            <Formik
              initialValues={customerValues}
              validationSchema={CRValidationSchema}
              onSubmit={async (values) => {
                // const customerDetails = values;
                const bookingDetails = currentOrder;
                // const customerInput = inputData;
                const bookingData = {
                  bookingDetails,
                  customerDetails: values,
                };
                localStorage.setItem(
                  'booking_data',
                  JSON.stringify(bookingData)
                );
                router.back();
              }}
            >
              {({
                values,
                handleChange,
                handleBlur,
                touched,
                errors,
                setFieldValue,
              }) => {
                const onValueChange = (phoneNumber) => {
                  setFieldValue('telephone', phoneNumber);
                };
                const onCountryChange = (country) => {
                  setFieldValue('country', country);
                  setFieldValue('state', '');
                };
                return (
                  <Form>
                    <div className="row">
                      <div className="col-xl-6 col-md-6">
                        <label htmlFor="people">First name*</label>
                        <MyTextInput
                          name="firstName"
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <div className="col-xl-6 col-md-6">
                        <label htmlFor="people">Last name*</label>
                        <MyTextInput
                          name="lastName"
                          type="text"
                          className="form-control"
                          id="people"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6 col-md-6">
                        <label htmlFor="email">Email*</label>
                        <MyTextInput
                          name="email"
                          type="email"
                          className="form-control"
                          id="email"
                        />
                      </div>
                      <div className="col-xl-6 col-md-6">
                        <label htmlFor="emailverify">Verify email*</label>
                        <MyTextInput
                          name="verifyemail"
                          type="email"
                          className="form-control"
                          id="emailverify"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-4 col-md-4">
                        <label htmlFor="country">Country*</label>
                        {/* <MySelect
                          name='country'
                          className='form-control'
                          placeholder='Choose one...'
                        >
                          {Countries.map((item, index) => (
                            <option key={index} value={item.code}>
                              {item.name}
                            </option>
                          ))}
                        </MySelect> */}
                        <CountryDropdown
                          name="country"
                          className="form-control"
                          value={values.country}
                          onChange={onCountryChange}
                          onBlur={handleBlur}
                        />
                        {touched.country && errors.country && (
                          <div
                            id="feedback"
                            style={{ marginLeft: '0.5rem', color: '#E95F5F' }}
                          >
                            {errors.country}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-8 col-md-8">
                        <label htmlFor="address">Address*</label>
                        <MyTextInput
                          name="address"
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-4 col-sm-2">
                        <label htmlFor="city">City*</label>
                        <MyTextInput
                          name="city"
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <div className="col-xl-2 col-sm-2">
                        <label htmlFor="state">State/Province*</label>
                        {/* <MySelect name='state' className='form-control'>
                          {States.map((item, index) => (
                            <option key={index} value={item.code}>
                              {item.name}
                            </option>
                          ))}
                        </MySelect> */}
                        <RegionDropdown
                          name="state"
                          className="form-control"
                          country={values.country}
                          value={values.state}
                          onChange={(_, e) => handleChange(e)}
                        />
                        {touched.state && errors.state && (
                          <div
                            id="feedback"
                            style={{ marginLeft: '0.5rem', color: '#E95F5F' }}
                          >
                            {errors.state}
                          </div>
                        )}
                      </div>
                      <div className="col-xl-2 col-sm-2">
                        <label htmlFor="zip">Zip*</label>
                        <MyTextInput
                          name="zipcode"
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="row" style={{ paddingBottom: '1.5rem' }}>
                      <div className="col-xl-6 col-md-6">
                        <label htmlFor="mobile">Mobile*</label>
                        <PhoneInput
                          international
                          name="telephone"
                          defaultCountry="US"
                          initialValueFormat="national"
                          value={values.telephone}
                          onBlur={handleBlur}
                          onChange={onValueChange}
                          style={{
                            border: '1px solid #447969',
                            padding: '5px',
                          }}
                        />
                        {touched.telephone && errors.telephone && (
                          <div
                            id="feedback"
                            style={{ marginLeft: '0.5rem', color: '#E95F5F' }}
                          >
                            {errors.telephone}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row row-padding-bottom">
                      <div className="col-12">
                        <button type="submit" className="btn btn-primary">
                          Continue
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
