import React, { useEffect } from "react";
import "./../styles/globals.css";
import "../../public/css/bootstrap.min.css";
import "../../public/css/hawaii.css";
import "../../public/css/datepicker.css";
import { Layout } from "Molecules/";
import { OrderProvider } from "Lib/contexts/OrderContext";
import { TimerProvider } from "Lib/contexts/TimerContext";
import { SubFooter } from "Atoms/";
import { useRouter } from "next/router";
import Script from "next/script";
import { Config } from 'Lib/config';
import API from "Services/api";

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const { pathname } = router;
  
  useEffect(() => {
    async function apiHit() {
      try {
        const { data } = await API.get("/parks/notices")

        if (data?.message === "Success") {
          localStorage.setItem('notices', JSON.stringify(data?.data));
        }
      } catch (error) {
        console.log(error);
      }
    }
    apiHit();
  }, []);

  return (
    <>
    
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-PB5Y3TK0NH"
        strategy="afterInteractive"
      />
      <Script
        id="google-analytics"
        strategy="afterInteractive"
      >
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag()
        
        {dataLayer.push(arguments);}
        gtag('js', new Date());
        
        gtag('config', 'G-PB5Y3TK0NH');
        `}
      </Script>
      
      <Script id="freshChat" strategy="afterInteractive">
        {`
          window.fcWidget.init({
              token: '${Config.FRESHCHAT_TOKEN}',
              host: 'https://wchat.freshchat.com',
              siteId: '${Config.FRESHCHAT_ID}',
              config: {
                hideFAQ: true,
                cssNames: {
                  widget: "freshchat-widget"
                }
              }
          });
        `}
      </Script>
      
      
      {pathname === "/" || pathname === "/inquiry" ? (
        <OrderProvider>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </OrderProvider>
      ) : (
        <OrderProvider>
          <TimerProvider>
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </TimerProvider>
        </OrderProvider>
      )}
    </>
  );
}

export default MyApp;
