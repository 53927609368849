import React from "react";
import { Formik, Form } from "formik";
import { MyPhoneInput, MyTextInput } from "Atoms/Formik";
import { LookupSchema } from "Lib/validators";
import API from "Services/api";
import { useRouter } from "next/router";
import Link from "next/link";

export default function Index({url, parkid}) {
  let router = useRouter();
  var customerValues = {
    email: "",
    orderNumber: "",
    telephone: "",
    lastName: "",
    parkid: parkid
  };
  return (
    <div>
      <div className="container">
        <div className="row row-padding">
          <div className="col-12">
            <h1>Reservation look up</h1>
          </div>
          <div className="col-12">
            <p>
              Please enter the email address used and your order #, your mobile
              number or your last name used for the reservation.
            </p>
          </div>
        </div>
        <div className="row row-padding-bottom">
          <div className="col-md-6">
            <Formik
              initialValues={customerValues}
              validationSchema={LookupSchema}
              onSubmit={async (values) => {
                try {
                  const { data } = await API.post(
                    "/reservations/search",
                    values
                  );
                  //console.log(data);
                  localStorage.removeItem("lookup_data");
                  localStorage.setItem(
                    "lookup_data",
                    JSON.stringify(data?.data)
                  );
                  router.push("/" + url + "/change-reservation-list");
                } catch (error) {
                  alert(error);
                }
              }}
            >
              {(props) => {
                return (
                  <Form>
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="email">
                          {" "}
                          <strong>Email*</strong>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <MyTextInput
                          name="email"
                          type="email"
                          className="form-control"
                          id="email"
                        />
                        <p style={{ margin: "0rem 0 1.2rem" }}>
                          <em>and</em>
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="lasrName">
                          <strong>Last name</strong>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <MyTextInput
                          name="lastName"
                          type="text"
                          className="form-control"
                        />
                        <p style={{ margin: "0rem 0 1.2rem" }}>
                          <em>or</em>
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="mobile">
                          <strong>Mobile #</strong>
                        </label>
                      </div>
                      <div className="col-md-8">
                        {/* <MyTextInput
                          name="mobile"
                          type="text"
                          className="form-control"
                        /> */}
                        <MyPhoneInput
                          name="telephone"
                          className="form-control"
                          formikObj={props}
                          style={{
                            padding: "5px",
                          }}
                        />
                        <p style={{ margin: "0rem 0 1.2rem" }}>
                          <em>or</em>
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="orderNumber">
                          {" "}
                          <strong>Order #</strong>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <MyTextInput
                          name="orderNumber"
                          type="text"
                          className="form-control"
                          // id='people'
                        />
                      </div>
                    </div>
                    <br />
                    <div className="row row-padding-bottom">
                      <div className="col-md-4"></div>
                      <div className="col-md-8">
                        <Link href="/diamondhead">
                          <a className="btn btn-primary btn-outline-primary">
                            Cancel
                          </a>
                        </Link>
                        <button type="submit" className="btn btn-primary ml-2">
                          Find
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
