import React, { useContext, useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { Loader, PaymentLoader } from "Atoms/";
import { Modal } from "react-bootstrap";
import Payment from "./Payment";
import moment from "moment";
import { TimerContext } from "Lib/contexts/TimerContext";
import Timer from "src/components/Timer";
import CancelReservationButton from "Atoms/CancelReservationButton";
import EditReservationButton from "Atoms/EditReservationButton";
import toast, { Toaster } from "react-hot-toast";
import API from "Services/api";

export default function Index({park, url, entrynotice}) {
  const router = useRouter();
  const { clearTimer, getDeadTime } = useContext(TimerContext);
  const [bookingData, setBookingData] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [flag, setFlag] = useState(true);
  const [paymentFail, setPaymentFail] = useState(null);
  const { handleCartReset } = useContext(TimerContext);
  
  const [resEntryNotice, setResEntryNotice] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("booking_data")) {
      router.push("/" + url);
    } else {
      setLoading(true);
      setBookingData(JSON.parse(localStorage.getItem("booking_data")));
      // clearTimer(getDeadTime());
    }
    
    const bookingDetails = JSON.parse(
      localStorage.getItem("booking_details")
    );
    
    if(bookingDetails.parkId !== park) {
      router.push("/" + url);
    }
    
    setResEntryNotice(entrynotice);
  }, []);

  if (!loading) {
    return <Loader />;
  }
  const { bookingDetails, customerDetails } = bookingData;

  const { pricingDetails, total, date, addOn } = bookingDetails;
  const {
    address,
    city,
    country,
    email,
    firstName,
    lastName,
    state,
    telephone,
    zipcode,
  } = customerDetails;
  
  const handlePay = async () => {
    handleShow();
    setPaymentLoading(true); 
    
    const customerInput = JSON.parse(
      localStorage.getItem("customer_input")
    );
    
    const { date, parkId, quantity, ticketId, timeslots } =
    customerInput;
    
    const formattedTimeslots = timeslots.map(({ slotId, label }) => ({
      id: `${slotId}`,
      label,
    }));
    
    const bookingDetails = JSON.parse(
      localStorage.getItem("booking_details")
    );
    
    const {
      firstName,
      lastName,
      email,
      country,
      address,
      city,
      state,
      zipcode,
      telephone
    } = customerDetails;
    
    const obj = {
      reqID: bookingDetails.reqID,
      parkId,
      date,
      ticketId,
      quantity,
      timeslots: formattedTimeslots,
      customerData: {
        firstName,
        lastName,
        email,
        country,
        address,
        city,
        stateProvince: state,
        zip: zipcode,
        mobile: telephone
      },
      origin: 'visitor'
    };
    
    try {
      const { data } = await API.post("/reservations/reserve/visitor", obj);
      
      if (data.message === "Success") {
        let reservationId = data?.data?.reservationData?._id;
        handleCartReset(false);
        localStorage.removeItem("booking_data");
        localStorage.removeItem("booking_details");
        localStorage.removeItem("customer_input");
        localStorage.setItem("timer", 900);
        localStorage.setItem("should_show", JSON.stringify(true));
        
        router.push(data.data.checkoutUrl);
      }
      else {
        handlePaymentError('Payment Service Error');
      }
    } catch (error) {
      handlePaymentError('Payment Service Error');
    }
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handlePaymentError = (msg) => setPaymentFail(msg);

  const ErrorModal = () => (
    <div>
      {paymentFail}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "2em",
        }}
      >
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleClose}
        >
          OK
        </button>
      </div>
    </div>
  );
  return (
    <div>
      <div className="container">
        <div>
          <Toaster />
        </div>
        <div className="row row-padding">
          <div className="col-md-8 col-sm-8 col-xs-12">
            <h1>Final review</h1>
          </div>
          <Timer />
          <div className="col-md-12">
            <p>
              Please remember, once payment has been completed, the
              change/cancel fee may apply. Please review your reservation date
              and time carefully. Mahalo!
            </p>
          </div>
        </div>
        <div className="row line-bottom mt-2">
          <div className="col-xl-2 col-md-2 col-2">
            <p className="less-padding">
              <strong>Ticket</strong>
            </p>
          </div>
          <div className="col-xl-1 col-md-2 col-2">
            <p className="less-padding">
              <strong>Qty</strong>
            </p>
          </div>
          <div className="col-xl-2 col-md-2 col-3">
            <p className="less-padding">
              <strong>Date</strong>
            </p>
          </div>
          <div className="col-xl-3 col-md-4 col-3">
            <p className="less-padding">
              <strong>Time</strong>
            </p>
          </div>
          <div className="col-2 text-end">
            <p className="less-padding">
              <strong>Price</strong>
            </p>
          </div>
        </div>

        {pricingDetails?.map(({ ticket, qty, date, time, price }, index) => (
          <div key={index} className="row line-bottom">
            <div className="col-xl-2 col-md-2 col-2">
              <p className="less-padding">{ticket}</p>
            </div>
            <div className="col-xl-1 col-md-2 col-2">
              <p className="less-padding">{qty}</p>
            </div>
            <div className="col-xl-2 col-md-2 col-3">
              <p className="less-padding">
                {moment(new Date(date)).format("MM/DD/YYYY")}
              </p>
            </div>
            <div className="col-xl-3 col-md-4 col-3">
              <p className="less-padding">{time}</p>
            </div>
            <div className="col-2 text-end">
              <p className="less-padding">${price}</p>
            </div>
          </div>
        ))}

        <div className="row line-bottom">
          <div className="col-xl-2 col-md-2 col-2"></div>
          <div className="col-md-1 col-1"></div>
          <div className="col-xl-2 col-md-3 col-3"></div>
          <div className="col-xl-3 col-md-4 col-4">
            <p className="less-padding text-end">Order Processing Fee</p>
          </div>
          <div className="col-2 text-end">
            <p className="less-padding">${addOn}</p>
          </div>
        </div>
        <div className="row line-bottom">
          <div className="col-xl-2 col-md-2 col-2"></div>
          <div className="col-md-1 col-1"></div>
          <div className="col-xl-2 col-md-3 col-3"></div>
          <div className="col-xl-3 col-md-4 col-4">
            <p className="less-padding text-end">Total</p>
          </div>
          <div className="col-2 text-end">
            <p className="less-padding">${(parseFloat(total) + parseFloat(addOn)).toFixed(2)}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6 col-12">
            <p className="less-padding" style={{ paddingTop: "2rem" }}>
              <strong>Customer details:</strong>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-2 col-4">
            <p className="less-padding">Your name:</p>
          </div>
          <div className="col-xl-5 col-8">
            <p className="less-padding">
              {firstName} {lastName}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-2 col-4">
            <p className="less-padding">Email:</p>
          </div>
          <div className="col-xl-5 col-8">
            <p className="less-padding">{email}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-2 col-4">
            <p className="less-padding">Address:</p>
          </div>
          <div className="col-xl-5 col-8">
            <p className="less-padding">
              {address}
              <br />
              {city}, {state} {zipcode} {country}
            </p>
          </div>
        </div>
        <div className="row line-bottom">
          <div className="col-xl-2 col-4">
            <p className="less-padding">Telephone:</p>
          </div>
          <div className="col-xl-5 col-8">
            <p className="less-padding">{telephone}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-12">
            <p>
              <span className="red">
                Partial refunds will be offered if cancelled more than three
                (3) days in advance.
              </span>{" "}
              Modifications will be considered for parking and/or entry
              reservation times, on a space-available basis and done at least 3
              days in advance (additional fees may apply, partial refunds may or
              may not apply).
              <br />
              <br />
              <span className="red">We are not responsible</span> for
              last-minute closures of the park due to weather, safety concerns,
              road closures, or trail closures from any cause whatsoever. Full
              or partial refunds may be considered but will be at the sole
              discretion of the Hawaii State Parks Division.
            </p>
            {resEntryNotice &&
              <span className="red">
                <span style={{ fontWeight: "bold" }}>Entry only tickets without parking</span> are to be purchased only for guests accompanied by a Hawaiʻi resident.
                <br />
                <br />
              </span>
            }
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                onChange={() => setFlag(!flag)}
                id="flexCheckDefault"
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Please check here that you agree with all terms listed above.
              </label>
            </div>
          </div>
        </div>
        <div className="row row-padding-top">
          <div className="col-md-12">
            <CancelReservationButton url={url} />
            <EditReservationButton url={url} />
            <Link href={"/" + url}>
              <a className="btn btn-primary btn-outline-primary ml-2">Change</a>
            </Link>
            <button
              type="button"
              className="btn btn-primary ml-2"
              disabled={flag}
              onClick={handlePay}
              style={{ background: "#447969" }}
            >
              Pay
            </button>
            <Modal show={show} onHide={handleClose}>
              <Modal.Body>
                {paymentLoading ? (
                  !paymentFail ? (
                    <PaymentLoader />
                  ) : (
                    <ErrorModal />
                  )
                ) : (
                  <Payment
                    setPaymentLoading={setPaymentLoading}
                    handleClose={handleClose}
                    handlePaymentError={handlePaymentError}
                  />
                )}
              </Modal.Body>
            </Modal>
          </div>
        </div>

        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
