import React, { useState } from "react";
import API from "Services/api";
import toast, { Toaster } from "react-hot-toast";
import { useRouter } from "next/router";
import Link from "next/link";
import { Config } from 'Lib/config';

import { Loader, PaymentLoader } from "Atoms/";

const Index = ({ orderDetails, url }) => {
  const router = useRouter();
  const [isChecked, setIsChecked] = useState(false);
  console.log("orderDetails", orderDetails);
  const { reservationData, customerData, pricingDetails } = orderDetails;
  const { _id, orderNo, dateOfReservation } = reservationData;
  const { pricingDetails: bookingDetails, total } = pricingDetails;
  
  const [refundLoading, setRefundLoading] = useState(false);
  const [show, setShow] = useState(false);
  
  const {
    firstName,
    lastName,
    email,
    country,
    address,
    city,
    stateProvince,
    mobile,
    zip,
  } = customerData;
  
  const openRefundLoading = () => setRefundLoading(true);
  const closeRefundLoading = () => setRefundLoading(false);

  const handleCancel = async () => {
    try {
      openRefundLoading(); 
      
      /*const { data } = await API.post(`/reservations/cancel`, {
        reservationId: _id,
      });
      if (data.message === "Success") {
        toast.success("Your reservation has been cancelled");
        console.log(data?.data);
        document.getElementById("cancelmodel-close").click();
        localStorage.setItem("cancel_order_no", JSON.stringify(orderNo));
        router.push("/diamondhead/cancel-confirm");
      }*/
      
      const { data } = await API.post(`/reservations/cancel/visitor`, {
        reservationId: _id,
      })
      
      if (data.message === "Success") {
        toast.success("Your reservation has been cancelled");
        console.log(data?.data);
        document.getElementById("cancelmodel-closeAlt").click();
        localStorage.setItem("cancel_order_no", JSON.stringify(orderNo));
        router.push("/" + url + "/cancel-confirm");
        closeRefundLoading();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const currDate = new Date();
  const myDate = new Date(dateOfReservation);

  return (
    <div className="container">
      <div>
        <Toaster />
      </div>
      <div className="row row-padding-top">
        <div className="col-md-12">
          <h1>Cancel reservation</h1>
        </div>
      </div>
      <div className="row line-bottom">
        <div className="col-xl-2 col-md-2 col-2">
          <p className="less-padding">
            <strong>Ticket</strong>
          </p>
        </div>
        <div className="col-xl-1 col-md-2 col-2">
          <p className="less-padding">
            <strong>Qty</strong>
          </p>
        </div>
        <div className="col-xl-2 col-md-2 col-3">
          <p className="less-padding">
            <strong>Date</strong>
          </p>
        </div>
        <div className="col-xl-3 col-md-4 col-3">
          <p className="less-padding">
            <strong>Time</strong>
          </p>
        </div>
        <div className="col-2 text-end">
          <p className="less-padding">
            <strong>Price</strong>
          </p>
        </div>
      </div>
      {bookingDetails.map(({ ticket, qty, date, time, price }, index) => (
        <div className="row line-bottom">
          <div className="col-xl-2 col-md-2 col-2">
            <p className="less-padding">{ticket}</p>
          </div>
          <div className="col-xl-1 col-md-2 col-2">
            <p className="less-padding">{qty}</p>
          </div>
          <div className="col-xl-2 col-md-2 col-3">
            <p className="less-padding">{date}</p>
          </div>
          <div className="col-xl-3 col-md-4 col-3">
            <p className="less-padding">{time}</p>
          </div>
          <div className="col-2 text-end">
            <p className="less-padding">${price}</p>
          </div>
        </div>
      ))}

      <div className="row line-bottom">
        <div className="col-xl-2 col-md-2 col-2"></div>
        <div className="col-md-1 col-1"></div>
        <div className="col-xl-2 col-md-3 col-3"></div>
        <div className="col-xl-3 col-md-4 col-4">
          <p className="less-padding text-end">Total</p>
        </div>
        <div className="col-2 text-end">
          <p className="less-padding">${total}</p>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-2 col-4">
          <p className="less-padding">Order #:</p>
        </div>
        <div className="col-xl-5 col-8">
          <p className="less-padding">{orderNo}</p>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-2 col-4">
          <p className="less-padding">Your name:</p>
        </div>
        <div className="col-xl-5 col-8">
          <p className="less-padding">
            {firstName} {lastName}
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-2 col-4">
          <p className="less-padding">Email:</p>
        </div>
        <div className="col-xl-5 col-8">
          <p className="less-padding">{email}</p>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-2 col-4">
          <p className="less-padding">Address:</p>
        </div>
        <div className="col-xl-5 col-8">
          <p className="less-padding">
            {address}
            <br />
            {city}, {stateProvince} {zip} {country}
          </p>
        </div>
      </div>

      <div className="row line-bottom">
        <div className="col-xl-2 col-4">
          <p className="less-padding">Telephone:</p>
        </div>
        <div className="col-xl-5 col-8">
          <p className="less-padding">{mobile}</p>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 col-12">
          <p>
            <span className="red">Cancellation Policy:</span> Once you cancel
            your reservation, you will no longer be able to use your ticket with
            the QR Code you received. You may not be able to make the
            reservation for the same day/time after you cancel your
            reservations. Please read the cancellation policy here&gt;&gt;
            <br />
            <Link href={"/" + url + "/about"}>
              <a target="_blank">
                {Config.WEB_URL}/{url}/about
              </a>
            </Link>
            <br />
          </p>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value={isChecked}
              onChange={() => setIsChecked(!isChecked)}
              id="flexCheckDefault"
            />
            <label className="form-check-label" for="flexCheckDefault">
              Please check here that you understand and agree to the
              cancellation policy.
            </label>
          </div>
        </div>
      </div>

      <div className="row row-padding-top">
        <div className="col-md-12">
          <Link href={"/" + url + "/change-reservation-list"}>
            <a className="btn btn-primary btn-outline-primary">Back</a>
          </Link>
          <button
            type="button"
            className="btn btn-primary ml-2"
            data-toggle="modal"
            data-target="#exampleModal"
            disabled={!isChecked}
          >
            Cancel reservation
          </button>

          <div
            className="modal fade"
            id="exampleModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
              role="document"
            >
              <div className="modal-content">
                { !refundLoading &&
                  <div>
                    <div className="modal-header text-start">
                      <div>
                        <h3>Are you sure you want to cancel this reservation?</h3>
                        {myDate - currDate <= 259200000 ? (
                          <p>
                            There will be no refund because the reservation is
                            within 3 days. Are you sure you want to cancel your
                            reservation?
                          </p>
                        ) : (
                          <p>
                            The cancellation fee $5.00 will be deducted from your
                            refund. Are you sure you want to cancel your
                            reservation?
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button onClick={handleCancel} className="btn btn-primary">
                        Yes
                      </button>{" "}
                      <button
                        id="cancelmodel-close"
                        data-dismiss="modal"
                        className="btn btn-primary btn-outline-primary"
                      >
                        Go back
                      </button>
                    </div>
                  </div>
                }
                { refundLoading &&
                  <div>
                    <PaymentLoader /> 
                    <button
                      id="cancelmodel-closeAlt"
                      data-dismiss="modal"
                      className="d-none"
                    >
                      Go back
                    </button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />
    </div>
  );
};

export default Index;
