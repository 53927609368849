import React, { useContext, useEffect } from "react";
import "cleave.js/dist/addons/cleave-phone.ng";
import Cleave from "cleave.js/react";
import { MyPhoneInput, MySelect, MyTextInput } from "Atoms/Formik";
// import { Countries, States } from 'Lib/constants';
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Formik, Form } from "formik";
import { PaymentSchema } from "Lib/validators";
import API from "Services/api";
import router from "next/router";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { TimerContext } from "Lib/contexts/TimerContext";

export default function Payment({
  setPaymentLoading,
  handleClose,
  handlePaymentError,
}) {
  const { handleCartReset } = useContext(TimerContext);

  var customerValues = {
    creditCardValue: "",
    creditCardType: "",
    expDate: "",
    cvv: "",
    firstName: "",
    lastName: "",
    email: "",
    verifyemail: "",
    country: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    telephone: "",
  };
  if (localStorage.getItem("booking_data")) {
    const bookingData = JSON.parse(localStorage.getItem("booking_data"));
    const { customerDetails } = bookingData;
    customerValues = {
      ...customerDetails,
    };
  }
  useEffect(() => {
    function apiHit() {
      let accept = document.createElement("script");
      accept.setAttribute("src", process.env.NEXT_PUBLIC_PG_URL);
      document.body.appendChild(accept);
      if (process.client) {
        window.checkout = this.checkout;
      }
    }
    apiHit();
  }, []);

  return (
    <div>
      
    </div>
  );
}
