import React from "react";
import { Footer } from "Atoms/";
import CookieConsent from "react-cookie-consent";

const Index = ({ children }) => {
  return (
    <>
      {children}
      <Footer />
      <CookieConsent expires={1} buttonText="OK">
        We serve cookies to analyze traffic and customize content on this site.
        By clicking OK in this banner, you agree to the use of cookies.{" "}
        <a
          href="https://portal.ehawaii.gov/page/privacy-policy"
          style={{ color: "#a9bb39" }}
        >
          Privacy Policy
        </a>
      </CookieConsent>
    </>
  );
};

export default Index;
