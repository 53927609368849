import React from "react";
import Link from "next/link";
import API from "Services/api";

export default function Index({ orderDetails, url, name }) {
  const { customerData, pricingDetails, reservationData } = orderDetails;
  const { orderNo, _id: orderId } = reservationData;
  const {
    firstName,
    lastName,
    mobile,
    email,
    country,
    zip,
    stateProvince,
    city,
    address,
  } = customerData;

  const { pricingDetails: pricingMap, total } = pricingDetails;

  const handleDownload = async () => {
    try {
      const { data } = await API.get("/reservations/downloadTicket", {
        params: { reservationId: orderId },

        responseType: "blob",
      });

      const url = URL.createObjectURL(data);
      
      console.log(url);
      const link = document.createElement("a");
      link.setAttribute('href', url);
      // link.download = orderNo + ".pdf";
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      link.setAttribute('download', orderNo);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.log(err);
    }
    // console.log("clicked");
  };

  return (
    <div>
      <div className="container">
        <div className="row row-padding">
          <div className="col-md-12">
            <h1></h1>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-2 col-4">
            <img
              src={
                "https://api.qrserver.com/v1/create-qr-code/?data=" +
                orderNo +
                "&size=200x200"
              }
              className="img-fluid"
            />
          </div>
          <div className="col-md-7 col-8">
            <h3 style={{ margin: ".5rem 0px 1.5rem 0px" }}>Order #{orderNo}</h3>
            <p style={{ paddingTop: ".2rem" }}>
              <strong>Your one time QR code for entry is to the left</strong>
            </p>
          </div>
          <div
            className="col-md-3 col-12 text-end"
            style={{ paddingTop: "1.5rem" }}
          >
          
            <button className="btn btn-primary" onClick={handleDownload}>
              Download ticket
            </button>
          </div>
        </div>

        <div className="row line-bottom">
          <div className="col-xl-2 col-md-2 col-2">
            <p className="less-padding">
              <strong>Ticket</strong>
            </p>
          </div>
          <div className="col-xl-1 col-md-2 col-2">
            <p className="less-padding">
              <strong>Qty</strong>
            </p>
          </div>
          <div className="col-xl-2 col-md-2 col-3">
            <p className="less-padding">
              <strong>Date</strong>
            </p>
          </div>
          <div className="col-xl-3 col-md-4 col-3">
            <p className="less-padding">
              <strong>Time</strong>
            </p>
          </div>
          <div className="col-2 text-end">
            <p className="less-padding">
              <strong>Price</strong>
            </p>
          </div>
        </div>

        {pricingMap?.map(({ ticket, qty, date, time, price }, index) => (
          <div key={index} className="row line-bottom">
            <div className="col-xl-2 col-md-2 col-2">
              <p className="less-padding">{ticket}</p>
            </div>
            <div className="col-xl-1 col-md-2 col-2">
              <p className="less-padding">{qty}</p>
            </div>
            <div className="col-xl-2 col-md-2 col-3">
              <p className="less-padding">{date}</p>
            </div>
            <div className="col-xl-3 col-md-4 col-3">
              <p className="less-padding">{time}</p>
            </div>
            <div className="col-2 text-end">
              <p className="less-padding">${price}</p>
            </div>
          </div>
        ))}

        <div className="row line-bottom">
          <div className="col-xl-2 col-md-2 col-2"></div>
          <div className="col-md-1 col-1"></div>
          <div className="col-xl-2 col-md-3 col-3"></div>
          <div className="col-xl-3 col-md-4 col-4">
            <p className="less-padding text-end">Total</p>
          </div>
          <div className="col-2 text-end">
            <p className="less-padding">${total}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-8 col-8">
            <div className="row">
              <div className="col-xl-6 col-12">
                <p className="less-padding" style={{ paddingTop: ".2 rem" }}>
                  <strong>Customer details:</strong>
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-3 col-4">
                <p className="less-padding">Your name:</p>
              </div>
              <div className="col-xl-5 col-8">
                <p className="less-padding">
                  {firstName} {lastName}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-3 col-4">
                <p className="less-padding">Email:</p>
              </div>
              <div className="col-xl-5 col-8">
                <p className="less-padding">{email}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-3 col-4">
                <p className="less-padding">Address:</p>
              </div>
              <div className="col-xl-5 col-8">
                <p className="less-padding">
                  {address}
                  <br />
                  {city}, {stateProvince} {zip} {country}
                </p>
              </div>
            </div>

            <div className="row row-padding-bottom">
              <div className="col-xl-3 col-4">
                <p className="less-padding">Telephone:</p>
              </div>
              <div className="col-xl-5 col-8">
                <p className="less-padding">{mobile}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-12">
            <Link href="/">
              <a className="btn btn-primary btn-outline-primary">
                Hawaii State Parks
              </a>
            </Link>
            <Link href={"/" + url}>
              <a className="btn btn-primary btn-outline-primary ml-2">
                {name} Home
              </a>
            </Link>
          </div>
        </div>

        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
