import { useRouter } from "next/router";
import { createContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useBeforeunload } from "react-beforeunload";
import API from "Services/api";

const TimerContext = createContext();

// testing husky
const TimerProvider = ({ children }) => {
  const Ref = useRef(null);
  const router = useRouter();

  const [timer, setTimer] = useState("");
  const [expirePopup, setExpirePopup] = useState(false);

  // convert the localStorage string to UI string
  const localToString = () => {
    let local = localStorage.getItem("timer");

    if (!local) {
      local = 900;
      localStorage.setItem("timer", 900);
    } else local = parseInt(local);

    let min = Math.floor(local / 60);
    let sec = local % 60;
    sec = sec < 10 ? "0" + sec : sec;
    min = min < 10 ? "0" + min : min;
    let str = min + ":" + sec;
    return str;
  };

  // ui string to localStorage
  const stringToLocal = () => {
    if (!!timer && timer !== "00:00") {
      const t = timer.split(":");
      localStorage.setItem("timer", parseInt(t[0]) * 60 + parseInt(t[1]));
    }

    if (timer === "00:00") {
      localStorage.setItem("timer", 900);
    }
  };

  // get the remaining time
  const getTimeRemaining = e => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds
    };
  };

  // set UI timer value
  const startTimer = e => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  // reset timer
  const clearTimer = e => {
    if (!Ref.current) {
      setTimer(localToString());

      if (Ref.current) clearInterval(Ref.current);

      const id = setInterval(() => {
        startTimer(e);
      }, 1000);

      Ref.current = id;
    } else {
      console.log("Context timer already running...", Ref.current);
    }
  };

  const stopTimer = () => {
    clearInterval(Ref.current);
    Ref.current = null;
    setTimer("");
    localStorage.setItem("timer", 900);
  };

  const extendTimeAtServer = async () => {
    const local = JSON.parse(localStorage.getItem("booking_details"));
    if (local && local.reqID) {
      const data = await API.post("/reservations/extendCart", {
        reqID: local.reqID
      });

      // console.log("REQ ID", data);
    }
  };

  // helper
  const getDeadTime = () => {
    let deadline = new Date();

    deadline.setSeconds(
      deadline.getSeconds() + parseInt(localStorage.getItem("timer"))
    );
    return deadline;
  };

  // remove the comments to start it automatically
  useEffect(() => {
    if (
      !!localStorage.getItem("timer") &&
      localStorage.getItem("timer") !== "900" &&
      localStorage.getItem("timer") !== "900"
    )
      clearTimer(getDeadTime());
  }, []);

  useEffect(() => {
    if(!process.env.NEXT_PUBLIC_DEV) {
      const unloadCallback = event => {
        console.log("here...closing the tab");
        handleCartReset();
        event.preventDefault();
        event.returnValue = "";
        return "";
      };
  
      window.addEventListener("beforeunload", unloadCallback);
      return () => window.removeEventListener("beforeunload", unloadCallback);
    }
  }, []);
  useEffect(() => {
    const timer = localStorage.getItem("timer");

    if (!timer || isNaN(timer)) {
      localStorage.setItem("timer", 900);
    } else {
      // const t = timer.split(':');
      // localStorage.setItem('timer', parseInt(t[0]) * 60 + parseInt(t[1]));
    }
  }, []);

  // automatically timer stop
  useEffect(() => {
    if (timer === "00:00") {
      setExpirePopup(true);

      setTimer("");
    }
  }, [timer]);

  useEffect(() => {
    stringToLocal();
  });

  // manual timer stop - user cancel / popup click
  const handleCartReset = async (sendToHome = true, url) => {
    try {
      const local = JSON.parse(localStorage.getItem("booking_details"));

      if (sendToHome && local && local.reqID) {
        const data = await API.post("/reservations/releasecapacity", {
          reqID: local.reqID
        });

        // console.log("REQ ID", data);
      }

      localStorage.removeItem("booking_data");
      localStorage.removeItem("customer_input");
      localStorage.removeItem("bookingDetails");
      localStorage.removeItem("booking_details");
      localStorage.removeItem("timer");

      clearInterval(Ref.current);

      Ref.current = null;
      setTimer("");
      localStorage.setItem("timer", 900);

      setExpirePopup(false);
      if (sendToHome) {
        if(url) {
          router.push("/" + url);
        }
        else {
          router.push("/");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <TimerContext.Provider
      value={{
        getDeadTime,
        clearTimer,
        startTimer,
        timer,
        setTimer,
        stopTimer,
        handleCartReset,
        extendTimeAtServer
      }}
    >
      {children}

      <Modal show={expirePopup}>
        <Modal.Body>
          <h3>Cart Expired!</h3>
          <p>Your shopping cart is expired. Please start over.</p>
          <br />
          <button
            className="btn btn-primary"
            onClick={() => handleCartReset()}
            style={{ marginBottom: "20px" }}
          >
            OK
          </button>
        </Modal.Body>
      </Modal>
    </TimerContext.Provider>
  );
};

export { TimerContext, TimerProvider };
