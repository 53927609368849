// cacnel cart button
// redirects to the home page -> /diamondhead/
import { TimerContext } from 'Lib/contexts/TimerContext';
import { useContext } from 'react';

const CancelReservationButton = ({url}) => {
  const { handleCartReset } = useContext(TimerContext);

  return (
    <button
      className='btn btn-primary btn-outline-primary'
      onClick={() => handleCartReset(true, url)}
    >
      Cancel
    </button>
  );
};

export default CancelReservationButton;
