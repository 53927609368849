import * as Yup from "yup";

const LookupSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  lastName: Yup.string().test(
    "oneOfRequired",
    "Atleast One Field Required",
    (value, context) => {
      return (
        context.parent.lastName ||
        context.parent.telephone ||
        context.parent.orderNumber
      );
    }
  ),
  orderNumber: Yup.string().test(
    "oneOfRequired",
    "Atleast One Field Required",
    (value, context) => {
      return (
        context.parent.lastName ||
        context.parent.telephone ||
        context.parent.orderNumber
      );
    }
  ),
  telephone: Yup.string().test(
    "oneOfRequired",
    "Atleast One Field Required",
    (value, context) => {
      return (
        context.parent.lastName ||
        context.parent.telephone ||
        context.parent.orderNumber
      );
    }
  ),
});
export default LookupSchema;
