import React from 'react';

export default function Inquiry() {
  return (
    <div>
      <div className="container">
        <div className="row row-padding">
          <div className="col-xl-12">
            <h2>For inquiries:</h2>
            <p>
              <strong>
                Department of Land and Natural Resources, Division of State
                Parks
              </strong>
              <br />
              <a href="https://dlnr.hawaii.gov/dsp/contact-us/" target="_blank">
                https://dlnr.hawaii.gov/dsp/contact-us/
              </a>
            </p>
            <p>
              <strong>
                About Your Reservations
              </strong>
              <br />
              Use our Live Chat to contact one of our agents about your reservation. Live Chat hours are between 7:45 a.m. and 4:30 p.m. HST (Hawaii Standard Time), Monday through Friday except Holidays. To get started, click on the chat icon on the bottom right of the page.
            </p>
            <p>
              <strong>About PUC Registrations</strong>
              <br />
              The Hawaii Public Utilities Commission: (808) 586-2020
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
