import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Loader } from 'Atoms/';
import Entries from './Entries';

export default function Index({url}) {
  const router = useRouter();
  const [lookupData, setLookupData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!localStorage.getItem('lookup_data')) {
      router.push('/' + url + '/change-reservation-list');
    } else {
      setLoading(true);
      const lookupDataParsed = JSON.parse(localStorage.getItem('lookup_data'));
      const filteredData = lookupDataParsed.filter(
        (item) => item.status === 'confirmed'
      );
      setLookupData(filteredData);
    }
  }, []);
  if (!loading) {
    return <Loader />;
  }
  return (
    <div>
      <div className="container">
        <div className="row row-padding-top">
          <div className="col-md-12">
            <h1>Reservations</h1>
          </div>
        </div>

        <div className="row line-bottom">
          <div className="col-xl-3 col-md-3 col-3">
            <p className="less-padding">
              <strong>Order #</strong>
            </p>
          </div>
          <div className="col-xl-3 col-md-3 col-3">
            <p className="less-padding">
              <strong>Date</strong>
            </p>
          </div>
          <div className="col-xl-5 col-md-5 col-5">
            <p className="less-padding">
              <strong>Action</strong>
            </p>
          </div>
        </div>
        {lookupData?.map((dataItem, index) => (
          <Entries key={index} dataItem={dataItem} url={url} />
        ))}

        {/* <div className='row row-padding-bottom'>
          <div className='col-xl-3 col-md-3 col-3'>
            <p className='less-padding'>
              <a href='reserve-confirmation.html'>1234567</a>
            </p>
          </div>
          <div className='col-xl-3 col-md-3 col-3'>
            <p className='less-padding'>9/1/2021</p>
          </div>
          <div className='col-xl-5 col-md-5 col-5'>
            <Link href='/visitor/change-reservation'>
              <a className='btn btn-primary btn-outline-primary'>Change</a>
            </Link>
            <a
              className='btn btn-primary btn-outline-primary ml-2'
              href='cancel-reservation.html'
            >
              Cancel
            </a>
            <a
              className='btn btn-primary btn-outline-primary ml-2'
              href='reserve-confirmation.html'
            >
              View
            </a>
          </div>
        </div> */}

        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
