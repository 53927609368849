import React, { useContext, useState } from "react";
import { Quantity } from "Lib/constants";
import { OrderContext } from "Lib/contexts/OrderContext";
import API from "Services/api";
import moment from "moment";

export default function EntryOrder({ park, dataItem }) {
  const { qty, date, price, time } = dataItem;
  const { currentOrder, inputData, setCurrentOrder, setInputData } =
    useContext(OrderContext);
  const { ticketId, date: bookingDate, timeslots } = inputData;

  const [disableQty, setDisableQty] = useState(false);

  const handleChangeQuantity = async (quantity) => {
    try {
      console.log("timeslots", timeslots);
      const obj = {
        parkId: park,
        segment: "visitor",
        ticketId,
        quantity,
        timeslots,
        date: bookingDate,
        reqID: currentOrder.reqID,
      };
      const { data } = await API.post("/reservations/fee", obj);
      if (data) {
        setCurrentOrder(data?.data);
        setInputData((prevObj) => ({ ...prevObj, quantity }));
        let bookingDetails = JSON.stringify(data?.data);
        let customerInput = JSON.stringify(obj);
        localStorage.removeItem("booking_details");
        localStorage.setItem("booking_details", bookingDetails);
        localStorage.setItem("customer_input", customerInput);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="row line-bottom">
        <div className="col-xl-1 col-md-2 col-2">
          <p className="less-padding">Entry</p>
        </div>
        <div className="col-xl-2 col-md-2 col-3 text-center">
          <select
            disabled={disableQty}
            className="form-control"
            id="Quantity"
            aria-label="Quantity"
            style={{ width: "80px", margin: "0 auto" }}
            onChange={(e) => {
              setDisableQty(true);
              handleChangeQuantity(e.target.value);
              setDisableQty(false);
            }}
            defaultValue={qty}
          >
            {Quantity.map((item) => (
              <option key={item}>{item}</option>
            ))}
          </select>
        </div>
        <div style={{ paddingLeft: "0" }} className="col-xl-2 col-md-2 col-2">
          <p className="less-padding">
            {moment(new Date(date)).format("MM/DD/YYYY")}
          </p>
        </div>
        <div className="col-xl-3 col-md-4 col-3">
          <p className="less-padding">{time}</p>
        </div>
        <div className="col-2 text-end">
          <p className="less-padding">${price}</p>
        </div>
      </div>
    </div>
  );
}
