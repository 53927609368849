import * as Yup from "yup";

const PUCMakePasswordSchema = Yup.object({
  password: Yup.string()
    .min(8, "Password must contain at least 8 characters")
    .required("Please enter your password")
    .test(
      "isValidPass",
      "Passowrd must contain atleast One Uppercase, Lowercase, Symbol, Number and No Space",
      (value, context) => {
        const hasUpperCase = /[A-Z]/.test(value);
        const hasNumber = /[0-9]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasSymbol = /["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(value);
        const hasSpace = /\s/.test(value);
        if (
          hasUpperCase &&
          hasNumber &&
          hasLowerCase &&
          hasSymbol &&
          !hasSpace
        ) {
          return true;
        }
        return false;
      }
    ),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});
export default PUCMakePasswordSchema;
