import React, { useState, useEffect } from "react";

export default function Index({park}) {
  const [notices, setNotices] = useState([]);
  
  useEffect(() => {
    setTimeout(function() {
      if(localStorage.getItem('notices')) {
        const globalNotices = JSON.parse(localStorage.getItem('notices'));
        const generalNotices = globalNotices.filter((notice) => notice.parks.includes(park))
        if(generalNotices) {
          setNotices(generalNotices)
        }
      }
    }, 1000)
  }, [park])
  
  return(
    <div>
      {notices && notices?.map((notice) => (
        <div class="alert alert-danger" role="alert">
          <strong>{notice.title}</strong>
          <br />
          <span>{notice.message}</span>
        </div>
      ))}
    </div>
  )
}