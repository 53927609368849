import React from 'react';
import moment from 'moment';

export default function ParkingOrders({ dataItem }) {
  const { qty, date, time, price } = dataItem;
  return (
    <div>
      <div className="row line-bottom">
        <div className="col-xl-1 col-md-2 col-2">
          <p className="less-padding">Parking</p>
        </div>
        <div className="col-xl-2 col-md-2 col-3">
          <p className="less-padding text-center">{qty}</p>
        </div>
        <div style={{ paddingLeft: '0' }} className="col-xl-2 col-md-2 col-2">
          <p className="less-padding">
            {moment(new Date(date)).format('MM/DD/YYYY')}
          </p>
        </div>
        <div className="col-xl-3 col-md-4 col-3">
          <p className="less-padding">{time}</p>
        </div>
        <div className="col-2 text-end">
          <p className="less-padding">${price}</p>
        </div>
      </div>
    </div>
  );
}
