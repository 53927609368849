import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import TextError from "../TextError";

const MyPhoneInput = ({ name, formikObj, style }) => {
  const { values, handleBlur, touched, errors, setFieldValue } = formikObj;

  return (
    <>
      <PhoneInput
        international
        countryCallingCodeEditable={false}
        name={name}
        defaultCountry={"US"}
        value={values.telephone}
        onChange={(e) => setFieldValue([name], e)}
        style={{ border: "1px solid #447969", ...style }}
      />

      {errors.telephone && <TextError>{errors.telephone}</TextError>}
    </>
  );
};

export default MyPhoneInput;
