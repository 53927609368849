import React from 'react';
import Link from 'next/link';

const Index = ({name, url}) => {
  return (
    <div className='sub-footer'>
      <div className='container' style={{ display: 'flex' }}>
        <div className='col-lg-9 col-md-8'>
          <p style={{ padding: '20px 0 20px' }}>
            <Link href={"/" + url}>
              <a>
                <strong>{name}:</strong>
              </a>
            </Link>
            <span className='Break'></span>&nbsp;
            <Link href={"/" + url + "/faq"}>
              <a>FAQ</a>
            </Link>{' '}
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link href={"/" + url + "/about"}>
              <a>About the Reservations</a>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Index;
