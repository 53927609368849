import { createContext, useState } from 'react';

const OrderContext = createContext();

const OrderProvider = ({ children }) => {
  const [currentOrder, setCurrentOrder] = useState({});
  const [inputData, setInputData] = useState('');
  const [customerFormData, setCustomerFormData] = useState('}');

  return (
    <OrderContext.Provider
      value={{
        customerFormData,
        setCustomerFormData,
        currentOrder,
        setCurrentOrder,
        inputData,
        setInputData,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export { OrderContext, OrderProvider };
