import React from "react";
import Link from "next/link";

const Index = () => {
  return (
    <footer>
      <div className="container" style={{ display: "flex", flexWrap: "wrap" }}>
        <div className="col-12 col-lg-7">
          <p style={{ paddingTop: "20px" }} className="text-md-center text-lg-left">
            <a
              href="https://portal.ehawaii.gov/page/privacy-policy/"
              target="_blank"
            >
              Privacy Policy
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a href="https://dlnr.hawaii.gov/dsp/" target="_blank">
              About DLNR/State Parks
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp;{" "}
            <Link href="/inquiry">
              <a>Inquiry</a>
            </Link>
          </p>
          <p className="text-center text-lg-left">
            Copyright &copy; {new Date().getFullYear()} Hawai'i State Parks. All
            Rights Reserved.
          </p>
        </div>
        <div className="col-12 col-lg-5 text-center text-lg-right" style={{ paddingTop: "10px" }}>
          <p>
            <a href="https://invest.hawaii.gov/made-in-hawaii/
" target="_blank"><img src="/images/made_in_hawaii_logo.png" width="100" /></a> &nbsp;
            &nbsp;
            <img src="/images/hawaii-state-parks-logo.png" width="100" /> &nbsp;
            &nbsp;
            <img
              src="/images/Seal_of_the_State_of_Hawaii.svg"
              width="90"
              alt="Seal of the State of Hawaii"
            />
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Index;
