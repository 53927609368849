import React, { useContext, useEffect } from "react";
import "cleave.js/dist/addons/cleave-phone.ng";
import Cleave from "cleave.js/react";
import { MyPhoneInput, MyTextInput } from "Atoms/Formik";
// import PhoneInput from 'react-phone-number-input';
// import { Countries, States } from 'Lib/constants';
import { Formik, Form } from "formik";
import { PUCPaymentSchema } from "Lib/validators";
import API from "Services/api";
import router from "next/router";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { TimerContext } from "Lib/contexts/TimerContext";

export default function Payment({
  setPaymentLoading,
  handleClose,
  grandTotal,
  handlePaymentError,
}) {
  const { stopTimer } = useContext(TimerContext);
  var initalValues = {
    creditCardValue: "",
    creditCardType: "",
    expDate: "",
    cvv: "",
    firstName: "",
    lastName: "",
    country: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    telephone: "",
  };
  if (localStorage.getItem("customer_data")) {
    const customerData = JSON.parse(localStorage.getItem("customer_data"));
    const {
      email: verifyemail,
      mobile: telephone,
      stateProvince: state,
    } = customerData;
    initalValues = {
      ...initalValues,
      ...customerData,
      verifyemail,
      telephone,
      state,
    };
  }

  useEffect(() => {
    function apiHit() {
      let accept = document.createElement("script");
      accept.setAttribute("src", process.env.NEXT_PUBLIC_PG_URL);
      document.body.appendChild(accept);
      if (process.client) {
        window.checkout = this.checkout;
      }
    }
    apiHit();
  }, []);

  return (
    <div>
      
    </div>
  );
}
