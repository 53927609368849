import React, { useState, useEffect, useRef, useContext } from "react";
import { Quantity } from "Lib/constants";
import API from "Services/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toast, { Toaster } from "react-hot-toast";
import { useRouter } from "next/router";
import Link from "next/link";
import moment from "moment";
import { TimerContext } from "Lib/contexts/TimerContext";
import { errorObj } from "Lib/constants";
import { Modal } from "react-bootstrap";

const Index = ({ orderDetails, url, parkid }) => {
  //console.log("orderDetails", orderDetails);
  let router = useRouter();
  const [formData, setFormData] = useState("");
  const datepickerRef = useRef(null);
  const { pricingDetails, reservationData, customerData } = orderDetails;
  const { pricingDetails: reservationDetails, total } = pricingDetails;
  const {
    _id: reservationId,
    parkId,
    ticketId,
    quantity,
    timeslots,
    dateOfReservation,
    orderNo,
  } = reservationData;
  customerData.orderNo = orderNo;
  const { stopTimer, clearTimer, getDeadTime } = useContext(TimerContext);
  const [bookingDate, setBookingDate] = useState(new Date(dateOfReservation));
  const [reservationQuantity, setReservationQuantity] = useState(quantity);
  const [timeSlotsData, setTimeSlotsData] = useState([]);
  const [dataChanged, setDataChanged] = useState(true);
  let timeslotsFiltered = timeslots.map(({ id, label }) => ({
    id: Number(id),
    label,
  }));
  const [timeSlots, setTimeSlots] = useState(timeslotsFiltered);
  const [show, setShow] = useState(false);

  useEffect(() => {
    async function apiHit() {
      const { data } = await API.get(
        "/parks/" + parkid + "/timeslots",
        {
          params: {
            segment: "visitor",
            ticketId,
            quantity: reservationQuantity,
            date: moment(new Date(bookingDate)).format("YYYY-MM-DD"),
          },
        }
      );
      if (data?.message === "Success") {
        const newTimeSlots = data?.data?.timeslots;
        setFormData(data?.data);
        setTimeSlotsData(newTimeSlots);
        newTimeSlots.forEach((item) => {
          if (isSlotPresent(item.slotId) && item.capacity <= 0) {
            setTimeSlots((prev) =>
              prev.filter((slot) => slot.id !== item.slotId)
            );
          }
        });
        // setFormData(data?.data);
        // setTimeSlotsData(data?.data?.timeslots);
      }
    }
    apiHit();
  }, [reservationQuantity, bookingDate]);

  const { ticketTypes } = formData;
  var parkandentryId;
  ticketTypes?.forEach((item) => {
    if (item.option === "Parking and entry") {
      parkandentryId = item.value;
    }
  });
  let parkingData;
  if (reservationDetails.length > 1) {
    parkingData = reservationDetails.filter(
      (item, index) => item.ticket === "Parking"
    );
  }

  const parkingTime = parkingData?.map((item, index) => item.time);

  function handleClickDatepickerIcon() {
    const datepickerElement = datepickerRef.current;
    datepickerElement.setFocus(true);
  }

  function isSlotPresent(slotId) {
    for (let i = 0; i < timeSlots.length; i++) {
      if (timeSlots[i].id === slotId) {
        return true;
      }
    }
    return false;
  }

  const handleGoBack = () => {
    router.push("/" + url + "/change-reservation-list");
    handleClose();
  };

  const handleCheckReservationUpdate = async () => {
    if (timeSlots.length === 0) {
      toast("Please select a time slot", errorObj);
      return;
    }
    try {
      const timeslots = timeSlots.map((item) => {
        const obj = {
          id: `${item.id}`,
          label: item.label,
        };
        return obj;
      });
      const updatedData = {
        reservationId,
        parkId,
        date: moment(new Date(bookingDate)).format("YYYY-MM-DD"),
        ticketId,
        quantity: reservationQuantity,
        timeslots,
      };
      const { data } = await API.post(
        "/reservations/checkReservationUpdate",
        updatedData
      );
      if (data.message === "Success") {
        localStorage.setItem(
          "updated_reservation_data",
          JSON.stringify(data?.response)
        );
        localStorage.removeItem("updated_data");
        localStorage.setItem("updated_data", JSON.stringify(updatedData));
        localStorage.setItem("customer_data", JSON.stringify(customerData));
        localStorage.setItem("pricing_data", JSON.stringify(pricingDetails));
        localStorage.setItem(
          "reservation_data",
          JSON.stringify(reservationData)
        );
        setDataChanged(data?.response?.dataChanged);
        handleShow();
        //document.getElementById('close-modal-button').click();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="container">
      <div>
        <Toaster />
      </div>
      <div className="row row-padding-top">
        <div className="col-md-12">
          <h1>Change reservation</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h3>Your current reservation:</h3>
        </div>
      </div>
      <div className="row line-bottom">
        <div className="col-xl-3 col-md-3 col-3">
          <p className="less-padding">
            <strong>Ticket</strong>
          </p>
        </div>
        <div className="col-xl-1 col-md-2 col-2">
          <p className="less-padding">
            <strong>Qty</strong>
          </p>
        </div>
        <div className="col-xl-2 col-md-2 col-3">
          <p className="less-padding">
            <strong>Date</strong>
          </p>
        </div>
        <div className="col-xl-2 col-md-3 col-2">
          <p className="less-padding">
            <strong>Time</strong>
          </p>
        </div>
        <div className="col-2 text-end">
          <p className="less-padding">
            <strong>Price</strong>
          </p>
        </div>
      </div>
      {reservationDetails.map(({ ticket, qty, date, time, price }, index) => (
        <div key={index} className="row line-bottom">
          <div className="col-xl-3 col-md-3 col-3">
            <p className="less-padding">{ticket}</p>
          </div>
          <div className="col-xl-1 col-md-2 col-2">
            <p className="less-padding">{qty}</p>
          </div>
          <div className="col-xl-2 col-md-2 col-3">
            <p className="less-padding">{date}</p>
          </div>
          <div className="col-xl-2 col-md-3 col-2">
            <p className="less-padding">{time}</p>
          </div>
          <div className="col-2 text-end">
            <p className="less-padding">${price}</p>
          </div>
        </div>
      ))}
      <div className="row line-bottom-dark">
        <div className="col-xl-2 col-md-2 col-2"></div>
        <div className="col-md-1 col-1"></div>
        <div className="col-xl-2 col-md-3 col-3"></div>
        <div className="col-xl-3 col-md-4 col-4">
          <p className="less-padding text-end">Total</p>
        </div>
        <div className="col-2 text-end">
          <p className="less-padding">${total}</p>
        </div>
      </div>

      <div className="row" style={{ border: "1px solid #bdd4db" }}>
        <form method="post" style={{width: "100%"}}>
          <div className="row reservation">
            <div className="col-12">
              <h3>Change your reservation to:</h3>
              <div className="row row-right-left-padding">
                <div className="col-12 col-md-6 col-lg-5">
                  <select
                    className="form-control"
                    id="exampleFormControlSelect2"
                    aria-label="Ticket type"
                    value={ticketId}
                    disabled
                    // onChange={(e) => setTicketType(e.target.value)}
                  >
                    {ticketTypes?.map(({ value, option }, index) => (
                      <option key={index} value={value}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                  {/* <div className="input-group input-daterange">
                    <input
                      type="text"
                      name="daterange"
                      value=""
                      readonly
                      aria-label="Date"
                    />{' '}
                  </div> */}
                  <div className="input-group input-daterange">
                    <DatePicker
                      id="datefield"
                      minDate={new Date(Date.now())}
                      maxDate={
                        new Date(
                          Date.now() +
                            3600 *
                              1000 *
                              24 *
                              parseInt(
                                process.env.NEXT_PUBLIC_RESERVATION_WINDOW
                                  ? process.env.NEXT_PUBLIC_RESERVATION_WINDOW
                                  : "14"
                              )
                        )
                      }
                      selected={bookingDate}
                      onChange={(date) => setBookingDate(date)}
                      placeholderText="Booking Date"
                      ref={datepickerRef}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-2 col-lg-2">
                  <select
                    className="form-control"
                    id="Quantity"
                    aria-label="Quantity"
                    value={reservationQuantity}
                    onChange={(e) => setReservationQuantity(e.target.value)}
                    
                  >
                    {Quantity.map((item) => (
                      <option key={item}>{item}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row row-right-left-padding">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="input-group mb-3">
                    {timeSlotsData?.map((item) => (
                      <a
                        key={item.slotId}
                        className={`btn time ${item.capacity <= 0 && "booked"}`}
                        style={{
                          backgroundColor:
                            isSlotPresent(item.slotId) && "#396a5b",
                          color: isSlotPresent(item.slotId) && "#fff",
                          pointerEvents: item.capacity <= 0 ? "none" : "auto",
                        }}
                        onClick={() => {
                          if (isSlotPresent(item.slotId)) {
                            setTimeSlots(
                              timeSlots.filter(
                                (slot) => slot.id !== item.slotId
                              )
                            );
                          } else {
                            if (timeSlots.length === 0) {
                              setTimeSlots([
                                { id: item.slotId, label: item.label },
                              ]);
                            } else if (parkandentryId === ticketId) {
                              if (timeSlots.length === 1) {
                                if (
                                  Number(item.slotId) ===
                                    Number(timeSlots[0].id) + 1 ||
                                  Number(item.slotId) ===
                                    Number(timeSlots[0].id) - 1
                                ) {
                                  setTimeSlots((prev) => [
                                    ...prev,
                                    { id: item.slotId, label: item.label },
                                  ]);
                                } else {
                                  toast("Selected wrong time slot.", errorObj);
                                }
                              } else {
                                toast("Select only two time slots.", errorObj);
                              }
                            } else {
                              toast("Select only one time slot.", errorObj);
                            }
                          }
                        }}
                      >
                        {item.label}
                      </a>
                    ))}
                    {/* <button
                      className="btn btn-primary time"
                      name="submit"
                      type="submit"
                    >
                      6:00am - 8:00am
                    </button>
                    <button
                      className="btn btn-primary time"
                      name="submit"
                      type="submit"
                    >
                      8:00am - 10:00am
                    </button>
                    <button
                      className="btn btn-primary time"
                      name="submit"
                      type="submit"
                      style={{
                        backgroundColor: '#396a5b !important',
                        color: '#ffffff',
                      }}
                    >
                      10:00am - 12:00pm
                    </button>
                    <button
                      className="btn btn-primary time"
                      name="submit"
                      type="submit"
                      style={{
                        backgroundColor: '#396a5b !important',
                        color: '#ffffff',
                      }}
                    >
                      12:00pm - 2:00pm
                    </button>
                    <button
                      className="btn btn-primary time booked"
                      name="submit"
                      type="submit"
                    >
                      2:00pm - 4:00pm
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="row row-padding-top">
        <div className="col-md-12">
          <Link href={"/" + url + "/change-reservation-list"}>
            <a className="btn btn-primary btn-outline-primary">Cancel</a>
          </Link>
          <button
            className="btn btn-primary ml-2"
            data-toggle="modal"
            data-target="#exampleModalName"
            data-backdrop="static"
            data-keyboard="false"
            onClick={handleCheckReservationUpdate}
          >
            Save all changes
          </button>

          {/* <div
            className="modal fade"
            id="exampleModalName"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalNameLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header text-start">
                  <div className="row">
                    <div className="col-md-12">
                      <p>Are you sure you want to update the reservation?</p>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-primary btn-outline-primary"
                    data-dismiss="modal"
                    aria-label="Close"
                    id="close-modal-button"
                  >
                    Cancel
                  </button>{" "}
                  {dataChanged ? (
                    <button
                      onClick={() => {
                        stopTimer();
                        clearTimer(getDeadTime());
                        router.push("/diamondhead/change-reservation-confirm");
                        document.getElementById("close-modal-button").click();
                      }}
                      className="btn btn-primary"
                    >
                      Confirm
                    </button>
                  ) : (
                    <button onClick={handleGoBack} className="btn btn-primary">
                      Go Back
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div> */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Body>
              <div className="modal-header text-start">
                <div className="row">
                  <div className="col-md-12">
                    <p>Are you sure you want to update the reservation?</p>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-primary btn-outline-primary"
                onClick={handleClose}
              >
                Cancel
              </button>
              {dataChanged ? (
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => {
                    stopTimer();
                    clearTimer(getDeadTime());
                    router.push("/" + url + "/change-reservation-confirm");
                    handleClose();
                  }}
                >
                  Confirm
                </button>
              ) : (
                <button onClick={handleGoBack} className="btn btn-primary">
                  Go Back
                </button>
              )}
            </Modal.Footer>
          </Modal>

          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default Index;
