// edit billing info button
// redirects to the edit page -> /diamondhead/reserve-details-expanded
import { TimerContext } from 'Lib/contexts/TimerContext';
import { useRouter } from 'next/router';
import { useContext } from 'react';

const EditReservationButton = ({url}) => {
  const router = useRouter();
  const { stopTimer, clearTimer, getDeadTime,extendTimeAtServer } = useContext(TimerContext);

  const handleTimerReset = () => {
    stopTimer();
    clearTimer(getDeadTime());
    extendTimeAtServer();
    router.push("/" + url + "/reserve-details-expanded");
  }

  return (
    <button
      className='btn btn-primary btn-outline-primary ml-2'
      onClick={() => handleTimerReset()}
    >
      Edit Details
    </button>
  );
};

export default EditReservationButton;
