import { isPossiblePhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";

const PUCRegisterSchema = Yup.object({
  company: Yup.string().required("Required"),
  yourName: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email address").required("Required"),
  verifyEmail: Yup.string()
    .required("Please confirm your email address")
    .when("email", {
      is: (email) => (email && email.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref("email")], "Email doesn't match"),
    }),
  telephone: Yup.string()
    .required("Required")
    .test(
      "isValidPhone",
      "Phone Number is not valid",
      (value) => value && isPossiblePhoneNumber(value)
    ),
  pucLicense: Yup.string().required("Please enter your license number"),
  address: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  zip: Yup.string().required("Required"),
  islands: Yup.array().min(1, "Please select at least one island"),
});
export default PUCRegisterSchema;
